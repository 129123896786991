<template>
  <div>
    <!-- Leads List -->
    <b-card title="Student Leads">
      <b-form-group>
        <b-form-input
          placeholder="Search..."
          @input="onChange"
          v-model="search"
        />
      </b-form-group>
      <b-table
        id="leadsTableId"
        ref="refLeadsTable"
        class="position-relative"
        :items="leadsList"
        responsive
        :fields="columns"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        primary-key="sd_id"
      >
        <template #cell(index)="data">
          {{ leadsList.indexOf(data.item) + 1 }}
        </template>

        <template #cell(referer_student)="data">
          {{ data.item.student_name }}
          <br />
          {{ data.item.student_email }}
        </template>
        <template #cell(student_creator)="data">
          <div v-if="data.item.student_creator_email">
            {{ data.item.student_creator_name }}
            <br />
            {{ data.item.student_creator_email }}
          </div>
          <div v-else>WEBSITE</div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  //   BAvatar,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormFile,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    // BAvatar,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormFile,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      leadsList: [],
      columns: [
        { key: "index", label: "#" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "mobile", label: "Mobile" },
        { key: "referer_student", label: "Referer Student" },
        { key: "student_creator", label: "Creator" },
      ],
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      debouce: null,
    };
  },
  methods: {
    onChange() {
      if (this.debouce) clearTimeout(this.debouce);
      this.debouce = setTimeout(() => {
        this.getStudentLeads();
      }, 1000);
    },
    async getStudentLeads() {
      try {
        const response = await CounselorServices.getStudentLeads({
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          search: this.search,
        });
        if (response.data.status) {
          this.leadsList = response.data.data.data;
        }
      } catch (error) {
        console.error("Error in getting student leads ", error);
      }
    },
  },
  beforeMount() {
    this.getStudentLeads();
  },
};
</script>


<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

